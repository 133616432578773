import React, { useContext, useEffect, useMemo, useState } from "react";
import { cacheBuster } from "./config";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";

declare global {
  interface Window {
    YT: any;
    onYouTubeIframeAPIReady: () => void;
  }
}

function NewsChannels() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  // Add new logo name variable here at the end after "news18", For eg: "newlogoname",
  const [tabs] = useState([
    "cnbctv18",
    "cnnnews18",
    "indiatoday",
    "ndtv24x7",
    "ndtvprofit",
    "newsx",
    "wion",
    "republictv",
    "news18",
  ]);

  const [selectedEnglishTab, setSelectedEnglishTab] = useState<string>(
    () => localStorage.getItem("selectedEnglishTab") || "indiatoday"
  );

  // Handle tab selection
  const handleTabClick = (channelKey: string) => {
    setSelectedEnglishTab(channelKey);
    localStorage.setItem("selectedEnglishTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedEnglishTab");
    if (savedTab) {
      setSelectedEnglishTab(savedTab);
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["cnbctv18", "cnnnews18"];
  const hiddenTabs: any = ["news18"];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedEnglishTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedEnglishTab(nextVisibleTab);
      }
    }
  }, []);

  /*****  To add new logo add this line with new logo name start ******/
  const cnbctv18 = `/assets/channel-logos/cnbctv18.png?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/
  const cnnnews18 = `/assets/channel-logos/cnnnews18.png?v=${cacheBuster}`;
  const indiatoday = `/assets/channel-logos/indiatoday.png?v=${cacheBuster}`;
  const ndtv24x7 = `/assets/channel-logos/ndtv24x7.png?v=${cacheBuster}`;
  const ndtvprofit = `/assets/channel-logos/ndtvprofit.png?v=${cacheBuster}`;
  const newsx = `/assets/channel-logos/newsx.png?v=${cacheBuster}`;
  const wion = `/assets/channel-logos/wion.png?v=${cacheBuster}`;
  const republictv = `/assets/channel-logos/republictv.png?v=${cacheBuster}`;
  const news18 = `/assets/channel-logos/news18.png?v=${cacheBuster}`;

  // Add new logo name after news18, For eg: newlogoname,
  const imagesToPreload = [
    cnbctv18,
    cnnnews18,
    indiatoday,
    ndtv24x7,
    ndtvprofit,
    newsx,
    wion,
    republictv,
    news18,
  ];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  const [iframeSrc, setIframeSrc] = useState("");
  const [title, setTitle] = useState("");

  // Mapping of selectedEnglishTab to corresponding titles
  const tabTitles = {
    indiatoday: "India Today Live",
    cnnnews18: "CNN News18 Live",
    ndtv24x7: "NDTV 24x7 Live",
    wion: "WION Live",
    newsx: "NewsX Live",
    cnbctv18: "CNBC TV18 24x7 Live",
    ndtvprofit: "NDTV Profit Live",
    republictv: "Republic TV Live",
    news18: "News18 Live",
  };

  // Dynamically load the iframe source and title based on the selected tab
  useEffect(() => {
    switch (selectedEnglishTab) {
      case "indiatoday":
        if (visibleTabs.includes("indiatoday")) {
          setIframeSrc(
            `https://www.youtube.com/embed/sYZtOFzM78M?autoplay=1&v=${cacheBuster}`
          );
          setTitle(tabTitles.indiatoday);
        }
        break;

      case "cnnnews18":
        if (visibleTabs.includes("cnnnews18")) {
          setIframeSrc(
            `https://www.youtube.com/embed/rfDx1HMvXbQ?autoplay=1&v=${cacheBuster}`
          );
          setTitle(tabTitles.cnnnews18);
        }
        break;

      case "ndtv24x7":
        if (visibleTabs.includes("ndtv24x7")) {
          setIframeSrc(
            `https://www.youtube.com/embed/sr2Ry9JVDtU?autoplay=1&v=${cacheBuster}`
          );
          setTitle(tabTitles.ndtv24x7);
        }
        break;

      case "wion":
        if (visibleTabs.includes("wion")) {
          setIframeSrc(
            `https://www.youtube.com/embed/gadjsB5BkK4?autoplay=1&v=${cacheBuster}`
          );
          setTitle(tabTitles.wion);
        }
        break;

      case "newsx":
        if (visibleTabs.includes("newsx")) {
          setIframeSrc(
            `https://www.youtube.com/embed/FJZB1Gq1X6Y?si=KHjv5v9agUsInOyM&autoplay=1&v=${cacheBuster}`
          );
          setTitle(tabTitles.newsx);
        }
        break;

      case "cnbctv18":
        if (visibleTabs.includes("cnbctv18")) {
          setIframeSrc(
            `https://www.youtube.com/embed/1_Ih0JYmkjI?autoplay=1&v=${cacheBuster}`
          );
          setTitle(tabTitles.cnbctv18);
        }
        break;

      case "ndtvprofit":
        if (visibleTabs.includes("ndtvprofit")) {
          setIframeSrc(
            `https://www.youtube.com/embed/ydiWklMUhcQ?autoplay=1&v=${cacheBuster}`
          );
          setTitle(tabTitles.ndtvprofit);
        }
        break;

      case "republictv":
        if (visibleTabs.includes("republictv")) {
          setIframeSrc(
            `https://www.youtube.com/embed/5IjtvziIeIw?autoplay=1&v=${cacheBuster}`
          );
          setTitle(tabTitles.republictv);
        }
        break;

      case "news18":
        if (visibleTabs.includes("news18")) {
          setIframeSrc(
            `https://www.youtube.com/embed/D5R8QyTGKVk?autoplay=1&v=${cacheBuster}`
          );
          setTitle(tabTitles.news18);
        }
        break;

      default:
        setIframeSrc(""); // Clear iframe if no tab is selected
        setTitle(""); // Clear title if no tab is selected
        break;
    }
  }, [selectedEnglishTab, visibleTabs, cacheBuster]);

  const videoIds = useMemo(
    () => [
      "sYZtOFzM78M",
      "rfDx1HMvXbQ",
      "sr2Ry9JVDtU",
      "gadjsB5BkK4",
      "FJZB1Gq1X6Y?si=KHjv5v9agUsInOyM",
      "1_Ih0JYmkjI",
      "ydiWklMUhcQ",
      "5IjtvziIeIw",
      "D5R8QyTGKVk",
    ],
    []
  );

  useEffect(() => {
    // Function to load the YouTube Iframe API script dynamically
    const loadYouTubeIframeAPI = () => {
      if (!window.YT) {
        const script = document.createElement("script");
        script.src = "https://www.youtube.com/iframe_api";
        script.async = true;
        document.body.appendChild(script);
      }
    };

    // Initialize YouTube Players for each video once API is ready
    window.onYouTubeIframeAPIReady = () => {
      videoIds.forEach((videoId, index) => {
        new window.YT.Player(`video-container-${index}`, {
          height: "450",
          width: "100%",
          videoId: videoId,
          events: {
            onReady: (event: any) => event.target.playVideo(),
          },
        });
      });
    };

    // Ensure that the YouTube API is loaded only once and trigger initialization
    if (window.YT && window.YT.Player) {
      window.onYouTubeIframeAPIReady();
    } else {
      loadYouTubeIframeAPI();
    }
  }, [videoIds]); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Live English News - Latest News Updates, Breaking News & Top
            Headlines
          </title>
          <meta
            name="description"
            content="Watch live English news from India Today, CNN News18, NDTV 24x7, and more. Stay updated with breaking news, top headlines, and real-time news updates in English."
          />
          <meta
            name="keywords"
            content="English news, live news, latest English news, breaking English news, today’s top headlines, top English news, live news channels, India Today Live, CNN News18 Live, NDTV Live, Republic TV Live, WION Live, today’s news updates, business news, financial news."
          />
        </Helmet>
        <Header />

        {selectedOption === "English" && (
          <>
            <div className="logo-grid">
              <div className="tabs">
                {/******  To add new logo copy paste this div(from line number 123 to 142) and change channel name wherever necessary and change the order(order-1).. START *******/}
                {visibleTabs.includes("indiatoday") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "indiatoday" ? "active" : ""
                    } order-1`}
                    onClick={() => handleTabClick("indiatoday")}
                  >
                    {/**** When you add new logo change the src={indiatoday} to src={newlogoname} in below div start ****/}
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={indiatoday}
                        alt="India Today News"
                      />
                    </div>
                    {/**** When you add new logo change the src={indiatoday} to src={newlogoname} in below div end ****/}
                  </div>
                )}
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order.. END *******/}

                {visibleTabs.includes("cnnnews18") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "cnnnews18" ? "active" : ""
                    } order-2`}
                    onClick={() => handleTabClick("cnnnews18")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={cnnnews18}
                        alt="CNN News18"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("ndtv24x7") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "ndtv24x7" ? "active" : ""
                    } order-3`}
                    onClick={() => handleTabClick("ndtv24x7")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={ndtv24x7}
                        alt="NDTV 24x7"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("wion") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "wion" ? "active" : ""
                    } order-4`}
                    onClick={() => handleTabClick("wion")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={wion}
                        alt="WION"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("newsx") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "newsx" ? "active" : ""
                    } order-5`}
                    onClick={() => handleTabClick("newsx")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={newsx}
                        alt="NewsX"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("cnbctv18") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "cnbctv18" ? "active" : ""
                    } order-6`}
                    onClick={() => handleTabClick("cnbctv18")}
                  >
                    <div className="image-container">
                      <img
                        rel="preload"
                        height="80"
                        width="80"
                        src={cnbctv18}
                        alt="CNBC TV18"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("ndtvprofit") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "ndtvprofit" ? "active" : ""
                    } order-7`}
                    onClick={() => handleTabClick("ndtvprofit")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={ndtvprofit}
                        alt="NDTV Profit"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("republictv") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "republictv" ? "active" : ""
                    } order-8`}
                    onClick={() => handleTabClick("republictv")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={republictv}
                        alt="Republic TV"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("news18") && (
                  <div
                    className={`tab-item ${
                      selectedEnglishTab === "news18" ? "active" : ""
                    } order-9`}
                    onClick={() => handleTabClick("news18")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={news18}
                        alt="News18 Live"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Display the selected YouTube video  */}

            <div className="video-container" id="video-container">
              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary and change the src start  *******/}

              {iframeSrc && title && (
                <>
                  <h1>{title}</h1>
                  <iframe
                    width="100%"
                    height="450px"
                    src={iframeSrc}
                    title={title}
                    loading="lazy" // Lazy load the iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </>
              )}
            </div>
          </>
        )}

        <p style={{ display: "none" }}>
          Stay updated with the latest English news from around the globe. Get
          instant access to breaking news, current headlines, and in-depth
          coverage of today's top stories. Whether it's politics, business,
          technology, sports, or entertainment, we bring you the most recent and
          reliable news updates in English. Watch live broadcasts from leading
          channels like India Today, CNN News18, Republic TV, NDTV 24x7, WION,
          and many more. Catch the latest news in English today, and never miss
          a moment with real-time coverage, live streaming, and breaking news
          alerts.
        </p>

        <p style={{ display: "none" }}>
          Tune in for live updates on major global events, market trends, and
          exclusive interviews. Stay informed with the top English news
          channels, including CNBC TV18 and NDTV Profit, for financial and
          business news. Whether you’re looking for the latest English news or
          top headlines of the day, we have it all covered in one place.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
